@import url('https://fonts.googleapis.com/css2?family=Comfortaa');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.App {
  font-family: 'Montserrat', sans-serif;
  margin: 0 auto;
  width: 90vw;
}

h1 {
  color: #5fad65;
  font-family: 'Comfortaa', cursive;
}

.intro {
  margin: 3rem 0;
  max-width: 900px;
}

table {
  border-collapse: collapse;
  margin: 3rem 0;
  max-width: 600px;
  width: 100%;
}

tr:nth-child(2n + 1) {
  background: #dce0dc;
  color: #5fad65;
}

td {
  padding: 0.5rem;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.name {
  font-family: 'Comfortaa', cursive;
}

.more {
  cursor: pointer;
  text-align: right;
}

a, a:visited, a:hover {
  color: inherit;
}
